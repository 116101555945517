/*------------------------------------------------------------------
[Master Stylesheet]

Project: United
Version:  1.0
Assigned to:  WebNik
Primary use: United
-------------------------------------------------------------------*/

/* FONTS*/
@import 'https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Montserrat:400,700';
/* @import '../fonts/font-awesome-4.6.3/css/font-awesome.min.css'; */
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css';
/* @import '../fonts/stroke/style.css'; */
/* @import 'https://cdn.jsdelivr.net/npm/@icon/elegant-icons@0.0.1-alpha.4/elegant-icons.css'; */
/* @import 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css'; */
/* @import url('https://fonts.googleapis.com/css?family=Circe'); */
/* @import url('https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css'); */
/* @import url('../fonts/circe/stylesheet.css'); */

/* PLUGIN CSS */

@import '../libs/bootstrap/bootstrap.min.css';                /* bootstrap */
@import '../plugins/headers/header.css';                /* header */
@import '../plugins/headers/yamm.css';                /* header */
@import '../plugins/slider-pro/slider-pro.css';         /* main slider */
@import '../plugins/owl-carousel/owl.theme.css';                 /* other sliders */
@import '../plugins/magnific-popup/magnific-popup.css';            /* modal */
@import '../plugins/animate/animate.css';                        /* animations */

@import '../plugins/TiltHoverEffects/css/component.css';                        /* animations */

/* MAIN CSS */

@import 'theme.css';
@import 'color.css';
@import 'responsive.css';


.no-mb {
  margin-bottom: 0 !important;
}

.header-f {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  font: 400 13px/2.15385 Merriweather !important;
  color: #777 !important;
  box-sizing: border-box !important;
  display: block !important;
  transform: translate(0px, 0px) !important;
  position: fixed !important;
  z-index: 1030 !important;
  border-radius: 0 !important;
  top: 0 !important;
  border-width: 0 0 1px !important;
  transition: all 0.3s ease-out !important;
  left: 50px !important;
  right: 50px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  padding-top: 0 !important;
}

.icon-consulting {
  color: red
}

.capb-icon {
  display: inline;
}

.capb-icon {
  width: 40px;
  position: absolute;
  left: -3px;
  top: 17px;
}

.offer-icon {
  width: 40px;
  /* padding-left: 10px; */
  /* position: absolute; */
  /* right: 90px; */
}

.whyus-icon {
  width: 40px;
  display: block;
  /* margin-bottom: 10px; */
  margin-right: 5px;
}

.logo {
  position: absolute;
  top: 5px;
}

.normal-logo {
  width: 120px !important;
  /* height: 12px !important; */
}

.services-title {
  margin: 0 !important;
  padding: 0 !important;
}

.services-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 28px;
}

.b-pricing__title {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 28px;
  font-size: 1.3rem;
}
