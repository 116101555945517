/*///////////////////////////////////////////////////////////////////////////////////////////*/
/* RESPONSIVE CSS */
/*///////////////////////////////////////////////////////////////////////////////////////////*/

@media (min-width:1441px) {
    .parallax {
        background-size: cover;
    }
    .header-boxed-width .container-boxed-width.container {
        max-width: 1820px !important;
    }
    .b-advantages-1_mod-a {
        padding-left: 50px;
        padding-right: 40px;
    }
    .b-advantages-1_mod-a .b-advantages__title {
        padding-right: 100px;
    }
    .b-advantages-1_mod-a .b-advantages__icon {
        right: 120px;
    }

    .sp-image {
        width: 100% !important;
    }
}


/* end 1441px */

@media (max-width:1350px) {
    .l-theme {
        padding-left: 0;
        padding-right: 0;
    }
    .section-maincontent {
        padding-left: 0;
        padding-right: 0;
    }
    .header {
        padding-top: 15px;
        left: 0;
        right: 0;
    }
    .footer {
        margin: 0;
        padding-right: 25px;
        padding-left: 25px;
    }
    .footer .copyright {
        padding: 30px 0 70px;
    }
    .main-slider_lg {
        margin-top: 0;
    }
}


/* end 1350px */

@media (min-width:1199px) and (max-width:1350px) {}


/* end 1199px-1350px */

@media (min-width:992px) and (max-width:1199px) {}


/* end 1200x */

@media (min-width:1200px) {
    .ui-subtitle-block {
        margin-bottom: 70px;
    }
}
/* end 992px-1199px */

@media (max-width:1199px) {
    .block-table_lg {
        display: block;
    }
    .section-first_type-1 .section-first__img-group {
        margin-top: 40px;
    }
    .section-first_type-1 .ui-title-bg {
        margin-top: 70px;
    }
    .section-first_type-2 {
        padding-bottom: 60px;
    }
    .section-first_type-2 .ui-title-bg {
        margin-top: 70px;
        font-size: 60px;
    }
    .b-brands-2 {
        padding-right: 50px;
        padding-left: 50px;
    }
}

/* end 1199px */

@media (min-width:992px) {
    .CookieConsent {
        font-size: 1.5rem;
    }
    .ui-wrap-title-block {
        text-align: center;
    }
}

@media (max-width:1040px) {
    .b-info__logo-big-container {
        text-align: left;
    }
}
/* end 1040px */

@media (max-width:991px) {
    html .tilter__figure::before {
        display: none !important;
    }
    html .sl-bottom,
    html .sl-top {
        border: 0px;
    }
    html .loader-text {
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        z-index: 99999;
        font-size: 19px;
        width: 400px;
        text-align: center;
        margin-left: -200px;
        margin-top: -60px;
    }
    .owl-theme.owl-carousel {
        margin-left: 0;
    }
    .owl-carousel .owl-item {
        padding-left: 0;
    }
    .footer .footer-section__list li {
        width: 100%;
    }
    .section-default {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .form-search-global__input {
        font-size: 30px;
    }
    .form-search-global__btn {
        top: 0;
        font-size: 18px;
    }
    .b-info__inner {
        margin-right: 0;
        margin-left: 0;
    }
    .block-table_md {
        display: block;
    }
    .b-pricing {
        margin-bottom: 30px;
    }
    .b-works__media {
        width: 300px;
    }
    .b-works:nth-child(odd) .b-works__inner {
        margin-right: 320px;
        margin-left: 0;
        padding-right: 30px;
    }
    .b-works:nth-child(even) .b-works__media {
        margin-left: 0;
    }
    .b-works:nth-child(even) .b-works__inner {
        padding-left: 30px;
        margin-left: 320px;
        margin-right: 0;
    }
    .section-first_type-2 .section-first__inner {
        margin-top: 90x;
    }
    .section-first_type-2 .ui-title-inner-2 {
        padding-right: 50px;
        padding-left: 50px;
    }
    .section-first_type-2 .ui-title-bg {
        margin-top: 20px;
    }
    .section-default .img-responsive {
        width: 100%;
    }
    .section-services {
        margin-bottom: 30px;
    }
    .section-blockquote {
        padding-left: 20px;
    }
    .section-blockquote__grid {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 20px;
    }
    .section-blockquote__quote-inner {
        height: 100px;
        display: inline-block;
    }
    .section-form-1 .ui-form,
    .section-form-1__description,
    .section-form-1 .ui-subtitle-block {
        padding-left: 0;
    }
    .b-progress-list {
        margin-left: -20px;
        margin-right: 0;
    }
    .b-progress-list__item {
        padding-left: 20px;
    }
    .posts-group {
        padding-bottom: 0;
    }
    .section-first_type-2,
    .section-features,
    .section-form-1,
    .section-blockquote {
        overflow: hidden;
    }
    .section-first__logo {
        display: none;
    }
    .section-first__text {
        display: inline-block;
    }
    .section-features {
        padding-top: 50px;
    }
    .section-form-1,
    .section-first_type-2 {
        padding-bottom: 50px;
    }
    .section-row-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    .scrollme.scroll-mac2,
    .scrollme.scroll-mac3 {
        display: none;
    }
    .b-advantages-2 {
        margin-bottom: 30px;
        padding-left: 55px;
    }
    .b-advantages-2 .b-advantages__title {
        line-height: 1.5;
    }

    .b-info__logo-big-container {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .b-info__logo-big {
        height: 150px;
    }
}


/* end 991px */

@media (max-width:991px) and (min-width:768px) {
    .grid-sizer,
    .grid-item {
        width: 33.33%;
    }
    .footer .footer-section {
        margin-top: 50px;
    }
    .footer .footer-section_list {
        padding-left: 20px;
    }
    .section-first__inner {
        padding-left: 0;
    }
    .section-first_type-2 {
        padding-top: 40px;
    }
    .section-form-1__inner {
        padding-right: 0;
        padding-left: 15px;
    }
    .b-blockquote-2 blockquote {
        padding: 65px 15px 0 50px;
    }
    .b-blockquote-2 .b-blockquote__cite {
        margin-top: 30px;
    }
    .b-pricing {
        padding: 20px 10px 10px;
    }
    .b-pricing__title {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .b-pricing__title .whyus-icon {
        width: 50px;
    }
    .b-info {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .b-info__title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 22px;
    }
    .b-info__link-bg .icon {
        padding-right: 15px;
        font-size: 100px;
    }
    .b-info__btn {
        font-size: 11px;
        letter-spacing: .3em;
    }
    .CookieConsent {
        font-size: 1.4rem;
        line-height: 1.2;
    }
    .CookieConsent button {
        font-size: 1.4rem !important;
        white-space: nowrap;
    }
    .footer .copyright {
        padding: 20px 0 55px;
    }

    .b-info__logo-big-container {
        margin-bottom: 20px;
    }
}

/* starts 768 */

@media (min-width: 768px) {
    .capabilities-tiles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .why-us-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .why-us-list__item {
        display: flex;
    }
}


/* end 768px */

@media (max-width:767px) {
    html .sl-bottom,
    html .sl-top {
        border: 0;
    }
    html .loader-text {
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        z-index: 99999;
        font-size: 19px;
        width: 300px;
        text-align: center;
        margin-left: -150px;
        margin-top: -60px;
    }
    .block-table_sm {
        display: block;
    }
    .b-isotope__filter li {
        display: block;
        margin-bottom: 10px;
    }
    .grid-sizer,
    .grid-item,
    .grid-item_wx2 {
        width: 100%;
        float: none;
    }
    .navbar-nav {
        margin: 0;
    }
    .about-author__inner {
        padding-left: 0;
    }
    .comments-list .children {
        margin-left: 20px;
    }
    .nav-tabs li {
        display: block;
    }
    p + p {
        margin-top: 10px;
    }
    .l-theme {
        padding-left: 0;
        padding-right: 0;
    }
    .section-maincontent {
        padding-left: 0;
        padding-right: 0;
    }
    .main-slider,
    .main-slider_lg {
        height: 600px;
    }
    .main-slider__info {
        padding-top: 120px;
    }
    .main-slider_lg .main-slider__info {
        padding-top: 140px;
    }
    .main-slider__title,
    .main-slider_lg .main-slider__title {
        font-size: 28px;
        margin-top: 40px;
        line-height: 1.6;
    }
    .main-slider__title-emphasis,
    .main-slider_lg .main-slider__title-emphasis {
        display: block;
    }
    .section-first_type-1 .section-first__inner {
        padding-left: 20px;
    }
    .section-features {
        padding-top: 40px;
    }
    .section-first__description {
        margin-bottom: 0;
    }
    .section-features .ui-description {
        margin-bottom: 25px;
    }
    .form-row-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    .form-row-reverse .section-form-1__img {
        padding-bottom: 30px;
    }
    .b-info {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .b-info__title {
        margin-top: 25px;
        margin-bottom: 15px;
        font-size: 19px;
    }
    .b-info__link-bg .icon {
        padding-right: 15px;
        font-size: 85px;
    }
    .b-info__btn {
        font-size: 11px;
        letter-spacing: .3em;
    }
    .ui-title-bg {
        font-size: 50px;
    }
    .b-progress-list__item {
        float: none;
        width: 100%;
    }
    .b-advantages-1_mod-a {
        display: block;
        margin-right: 0;
        padding: 0 20px 30px 20px;
        width: 100%;
    }
    .b-advantages-1_mod-a .services-container {
        margin-bottom: 15px;
    }
    .b-advantages-1 .b-advantages__icon {
        right: 10px;
    }
    .b-advantages-1 .b-advantages__info {
        margin-bottom: 0;
    }
    .b-advantages__info .services-container {
        margin-bottom: 15px;
    }
    .b-advantages-2 {
        padding-left: 60px;
        margin-bottom: 15px;
    }
    .b-advantages-2 .capb-icon {
        left: 2px;
    }
    .b-advantages-2 .b-advantages__title {
        margin-bottom: 10px;
    }
    .laptop-wrapp {
        margin: 0;
    }
    .scrollme-wrap {
        min-height: initial;
        padding-top: 15px;
    }
    .section-works__nav {
        position: relative;
        top: 0;
        margin-bottom: 30px;
    }
    .section-works__title-group {
        display: none;
    }
    .b-works:nth-child(odd) .b-works__media,
    .b-works:nth-child(even) .b-works__media {
        float: none;
        width: 100%;
    }
    .b-works:nth-child(odd) .b-works__inner {
        margin-right: 0;
        margin-top: 30px;
    }
    .b-works:nth-child(even) .b-works__inner {
        margin-left: 0;
        margin-top: 30px;
    }
    .section-blockquote {
        padding-bottom: 0;
    }
    .ui-subtitle-block {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .b-blockquote-2 blockquote {
        padding: 55px 15px 0 40px;
    }
    .b-blockquote-2 blockquote {
        font-size: 15px;
    }
    .b-blockquote-1 .b-blockquote__author {
        padding-right: 0;
        display: block;
        font-size: 15px;
    }
    .footer .footer-section_form {
        margin-left: 0;
    }
    .section-first__img-group {
        margin: 0 -15px;
    }
    .section-first_type-2 .ui-title-inner-2 {
        padding-right: 15px;
        padding-left: 15px;
    }
    .section-isotope {
        padding-top: 100px;
    }
    .b-isotope-filter > li {
        display: block;
        padding-left: 0;
    }
    .b-isotope-filter {
        text-align: center;
        margin-top: 0;
        padding-top: 20px;
        margin-bottom: 20px;
    }
    .b-isotope-filter__title {
        display: none;
    }
    .b-isotope-filter > li > a:after {
        left: -25px;
        top: -2px;
        content: '\f178';
    }
    .section-first_type-2 .ui-title-bg {
        font-size: 38px;
        margin-left: 15px;
    }
    .section-first_type-2 {
        padding-top: 30px;
        padding-bottom: 40px;
    }
    .section-first_type-2 .section-first__inner {
        padding-left: 0;
    }
    .section-first_type-2 .section-first__inner .btn {
        margin-top: 10px;
    }
    .section-services {
        padding-left: 0;
    }
    .section-form-1__inner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .block-table__inner img {
        padding-left: 0;
        width: 100%;
        height: auto;
    }
    .b-title-page {
        font-size: 30px;
    }
    .b-works-details__description dt {
        width: 100%;
        display: block;
        margin-bottom: 5px;
    }
    .b-works-details .ui-title-inner {
        margin-bottom: 5px;
    }
    .b-works-details__description dd {
        margin-left: 0;
    }
    .b-works-details__nav {
        padding-left: 0;
        padding-right: 0;
    }
    .section-first_type-3 .section-first__inner {
        margin-top: 70px;
        padding-left: 0;
    }
    .progress-block-group {
        padding-right: 0;
    }
    .progress-block-group_right {
        padding-left: 0;
    }
    .section-contact .ui-description {
        padding-left: 0;
        padding-right: 0;
    }
    .b-post-full .entry-main {
        padding-left: 0;
    }
    .b-post-full .entry-content {
        padding-right: 0;
    }
    .b-post .entry-meta__item {
        word-spacing: normal;
        display: inline-block;
    }
    blockquote.b-blockquote-post {
        padding-right: 0;
        padding-left: 50px;
    }
    blockquote.b-blockquote-post:after {
        left: 0;
    }
    .b-post-full .post-tags {
        float: none;
        margin-top: 10px;
    }
    .b-post-full .entry-footer__link {
        float: none;
    }
    .comments-list .comment-inner {
        padding-right: 10px;
        padding-left: 20px;
    }
    .comments-list .comment-btn {
        position: relative;
        right: auto;
        top: 0;
        display: block;
        top: 10px;
    }
    .back-to-top {
        bottom: 70px;
        padding: 1rem;
    }
    .CookieConsent {
        font-size: 1.2rem;
        line-height: 1.2;
    }
    .CookieConsent > div:first-child {
        flex: 2 0 65% !important;
    }
    .CookieConsent > div:last-child {
        flex-grow: 1;
        text-align: right;
    }
    .CookieConsent button {
        font-size: 1.2rem !important;
        white-space: nowrap;
    }
    .b-pricing {
        border: 0 none;
        margin-bottom: 0;
        padding: 25px 15px 15px 15px;
    }
    .b-pricing .b-pricing__title {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .b-pricing__title .whyus-icon {
        margin-bottom: 30px;
    }
    .why-us-list__item:last-child .b-pricing {
        margin-bottom: 0;
    }
    .section-form-1 {
        padding-bottom: 30px;
    }

    .section-form-1 .ui-form-1 .btn {
        margin-top: 0;
    }
    .blocks-inline > li {
        margin-right: 0;
        margin-left: 0;
    }
    .footer .footer-section {
        margin-top: 30px;
    }
    .footer__logo {
        margin: -30px 0 30px 0;
        flex-direction: column;
        align-items: center;
    }
    .footer__logo .footer__logo-title {
        max-width: 120px;
    }
    .footer__logo .footer__logo-image {
        max-width: 65px;
        margin-bottom: 15px;
        margin-right: 0;
    }
    .footer__info {
        margin-right: 0;
        text-align: center;
    }
    .footer .footer-section_list {
        display: none;
        padding-left: 0;
    }
    .footer .footer-section_list .footer-list {
        max-width: 320px;
        margin: 0 auto 25px;
    }
    .footer .footer-section_list .footer-section__title {
        padding-top: 20px;
    }
    .footer-section_list::after,
    .footer-section_list::before {
        border-bottom: 1px solid #444;
        content: '';
        display: block;
        max-width: 320px;
        margin: 0 auto;
    }
    .footer-list,
    .footer-form__info,
    .footer-section__title {
        text-align: center;
    }
    .footer-section_form {
        margin: 0;
    }
    .footer-section_form .footer-section__title {
        padding-top: 0;
    }
    .footer .copyright {
        padding: 30px 45px 45px 0;
    }
    .footer .footer-form__info {
        margin-bottom: 20px;
    }
    .footer-list__link {
        margin-bottom: 0;
        padding-bottom: 7px;
    }
    #showInfoModal {
        top: 60px;
    }
}

@media (max-width:767px) and (min-width:480px) {
    .blocks-inline > li {
        margin-right: 0;
        margin-left: 0;
    }
    .b-blockquote-2 .b-blockquote__cite {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .section-first_type-2 .section-first__header {
        max-width: 90%;
    }
}


/* end 767px-480px */

@media (max-width:480px) {
    .sp-buttons {
        bottom: 30px;
    }
    .navbar-fixed-bottom {
        position: absolute !important;
    }
    .comments-list .avatar-placeholder {
        float: none;
        margin-bottom: 30px;
    }
    .main-slider_lg .main-slider__info {
        font-size: 14px;
        padding-top: 120px;
    }
    .main-slider__title,
    .main-slider_lg .main-slider__title {
        font-size: 24px;
        height: 320px;
        line-height: 1.5;
        vertical-align: middle;
        display: table-cell;
        width: 100vw !important;
    }
    .pagination > li:first-child,
    .pagination > li:last-child {
        display: none;
    }
    .section-blockquote__quote-inner {
        padding-left: 65px;
        height: 75px;
    }
    .section-blockquote__quote-inner .icon {
        font-size: 45px;
        width: 150px;
    }
    .section-blockquote .figure {
        width: 310px;
        height: 60px;
    }
    .section-first_type-2 .section-first__header {
        max-width: 100%;
    }
    .b-blockquote-2 blockquote {
        font-size: 13px;
        padding: 50px 15px 0 20px;
    }
    .b-blockquote-2 .b-blockquote__author {
        font-size: 13px;
    }
    .b-blockquote-2 .b-blockquote__cite {
        margin-bottom: 25px;
        margin-top: 15px;
    }
    .ui-subtitle-block {
        font-size: 26px;
        margin-bottom: 25px;
    }
    .CookieConsent > div:first-child {
        flex: 2 0 62% !important;
        margin-right: 0 !important;
    }
    .section-first.section-first_type-2 br {
        display: block;
        line-height: 22px;
    }
    .footer {
        padding-right: 0;
        padding-left: 0;
    }
    .footer .copyright {
        padding: 20px 45px 55px 0;
        line-height: 1.5;
    }
    .footer-list__link {
        padding-top: 5px;
    }
    #showInfoModal {
        left: 15px;
        right: 15px;
        transform: translateX(0);
    }
    #showInfoModal .alert {
        text-align: center;
    }
}


/* end 480px */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sp-button,
    .owl-theme .owl-controls .owl-page span,
    .owl-theme .owl-controls .owl-page:hover span,
    .owl-theme .owl-controls .owl-page.active span {
        width: 14px;
        height: 14px;
    }
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
    .sp-button,
    .owl-theme .owl-controls .owl-page span,
    .owl-theme .owl-controls .owl-page:hover span,
    .owl-theme .owl-controls .owl-page.active span {
        width: 14px;
        height: 14px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
    .sp-button,
    .owl-theme .owl-controls .owl-page span,
    .owl-theme .owl-controls .owl-page:hover span,
    .owl-theme .owl-controls .owl-page.active span {
        width: 14px;
        height: 14px;
    }
}
