/*//////////////////////////////////////////////////////////////////////////////////////////*/
/* COLOR CSS 1*/
/*//////////////////////////////////////////////////////////////////////////////////////////*/

a,
.text-primary,
.text-primary_h:hover,
.text-primary_b:before,
.text-primary_a:after,
.list > li > a:hover,
.pager li > a:hover,
.pager li > a:hover .icon,
.pagination_primary > li:first-child > a:hover,
.pagination_primary > li:first-child > a:hover .icon,
.pagination_primary > li:last-child > a:hover,
.pagination_primary > li:last-child > a:hover .icon,
.search-close:hover,
.breadcrumb > li > a:hover,
.sp-arrow:hover,
.header .social-links > li > a:hover {
    color: #e0e242;
}

.bg-primary,
.bg-primary_h:hover,
.bg-primary_b:before,
.bg-primary_a:after,
.pagination_primary > .active > a,
.pagination_primary > .active > span,
.pagination_primary > .active > a,
.pagination_primary > .active > span,
.pagination_primary > li > a:hover,
.pagination_primary > li > a:focus,
.dropcap_primary:first-letter,
.tooltip-1 .tooltip-inner,
.btn-primary,
.forms__label-check-1:after,
.forms__label-radio-2:before,
.panel-default > .panel-heading,
.b-team .social-net,
.section-social-net__link:hover,
.hvr-shutter-in-vertical,
.b-pricing:before,
.owl-theme_pagin_act_prim .owl-controls .owl-page.active span,
.owl-theme_pagin_act_prim .owl-controls .owl-page:hover span,
.list-tags__link:hover,
.b-isotope-grid_mod-a .b-isotope-grid__item:hover .b-isotope-grid__wrap-info,
.header_mod-a .navbar .main-menu > li.active:before,
.header_mod-a .navbar .main-menu > li:hover:before {
    background-color: #e0e242;
}

.border_prim,
.border_prim_h:hover,
.pagination > .active > a,
.pagination > .active > span,
.pagination a:hover,
.pagination span:hover,
.pagination a:focus,
.pagination span:focus,
.progress_border_primary,
.btn-primary,
.forms__label-radio-2:before,
.list-tags__link:hover {
    border-color: #e0e242;
}

.border-t_prim,
.border-t_prim_h:hover,
.tooltip-1.top .tooltip-arrow,
.tooltip-1.top-left .tooltip-arrow,
.tooltip-1.top-right .tooltip-arrow,
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li.active > a {
    border-top-color: #e0e242;
}

.border-r_prim,
.border-r_prim_h:hover,
.tooltip-1.right .tooltip-arrow,
.b-progress-list__item,
.b-brands-2 {
    border-right-color: #e0e242;
}

.border-b_prim,
.border-b_prim_h:hover,
.tooltip-1.bottom .tooltip-arrow,
.tooltip-1.bottom-left .tooltip-arrow,
.tooltip-1.bottom-right .tooltip-arrow,
.table_primary > thead > tr > th,
.collapse.in,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    border-bottom-color: #e0e242;
}

.border-l_prim,
.border-l_prim_h:hover,
.tooltip-1.left .tooltip-arrow,
.border-left_primary:before,
.b-blockquote-1,
.b-brands-2 {
    border-left-color: #e0e242;
}

.b-blockquote-1:before,
.b-blockquote-post:after {
    text-shadow: -2px -2px 0 #e0e242, -2px -1px 0 #e0e242, -2px 0px 0 #e0e242, -2px 1px 0 #e0e242, -2px 2px 0 #e0e242, -1px -2px 0 #e0e242, -1px -1px 0 #e0e242, -1px 0px 0 #e0e242, -1px 1px 0 #e0e242, -1px 2px 0 #e0e242, 0px -2px 0 #e0e242, 0px -1px 0 #e0e242, 0px 0px 0 #e0e242, 0px 1px 0 #e0e242, 0px 2px 0 #e0e242, 1px -2px 0 #e0e242, 1px -1px 0 #e0e242, 1px 0px 0 #e0e242, 1px 1px 0 #e0e242, 1px 2px 0 #e0e242, 2px -2px 0 #e0e242, 2px -1px 0 #e0e242, 2px 0px 0 #e0e242, 2px 1px 0 #e0e242, 2px 2px 0 #e0e242;
}

.b-contact__icon {
    box-shadow: 25px 15px 0 -5px #e0e242;
}

.text-second,
.text-second_h:hover,
.link-tooltip-2,
.forms__label-check-2:after,
.pagination_secondary > li:first-child > a:hover,
.pagination_secondary > li:first-child > a:hover .icon,
.pagination_secondary > li:last-child > a:hover,
.pagination_secondary > li:last-child > a:hover .icon {
    color: #0097c2;
}

.bg-second,
.bg-second_h:hover,
.tooltip-2 .tooltip-inner,
.dropcap_secondary:first-letter,
.pagination_secondary > .active > a,
.pagination_secondary > .active > span,
.pagination_secondary > .active > a,
.pagination_secondary > .active > span,
.pagination_secondary > li > a:hover,
.pagination_secondary > li > a:focus,
.forms__label-radio-1:before {
    background-color: #0097c2;
}

.border_second,
.border_second_h:hover,
.progress_border_secondary,
.pagination_secondary > .active > a,
.pagination_secondary > .active > span,
.pagination_secondary > .active > a,
.pagination_secondary > .active > span,
.pagination_secondary > li > a:hover,
.pagination_secondary > li > a:focus,
.forms__label-radio-1:before {
    border-color: #0097C2;
}

.border-t_second,
.border-t_second_h:hover,
.tooltip-2.top .tooltip-arrow,
.tooltip-2.top-left .tooltip-arrow,
.tooltip-2.top-right .tooltip-arrow {
    border-top-color: #0097c2;
}

.border-r_second,
.border-r_second_h:hover,
.tooltip-2.right .tooltip-arrow {
    border-right-color: #0097c2;
}

.border-l_second,
.border-l_second_h:hover,
.tooltip-2.left .tooltip-arrow {
    border-left-color: #0097c2;
}

.border-b_second,
.border-b_second_h:hover,
.tooltip-2.bottom .tooltip-arrow,
.tooltip-2.bottom-left .tooltip-arrow,
.tooltip-2.bottom-right .tooltip-arrow,
.table_secondary > thead > tr > th {
    border-bottom-color: #0097c2;
}